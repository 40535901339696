import { css } from 'styled-components';

export const cardInput = css`
  .StripeElement {
    display: block;
    font-family: 'paralucent-medium', sans-serif;
    border: 2px solid ${({ theme }) => theme.grey};
    border-radius: 9px;
    padding: 11px;
    outline: 0;
    background: white;
  }

  .StripeElement--focus {
    border: 2px solid ${({ theme }) => theme.green};
  }
  .StripeElement--invalid {
    border: 2px solid ${({ theme }) => theme.red};
  }
`;

export const toastStyles = css`
  .Toastify__toast-container {
    width: calc(100% - 20px);
    max-width: 367px;
    margin: 4px 10px 4px 10px;
  }

  .Toastify__toast {
    border: 1px solid #dedede;
    border-radius: 8px;
    background-color: white;

    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    font-family: 'paralucent-medium', sans-serif;
    font-size: 18px;
  }

  .Toastify__toast-body {
    padding: 0 0 0 10px;
  }

  .Toast__description {
    color: #1e1e1e;
    margin: 6px 0 0 0;
  }

  .Toast__content {
    margin: 10px 0 24px 0;
  }

  .Toastify__toast--success {
    color: ${({ theme }) => theme.darkGreen};
  }

  .Toastify__toast--warning {
    color: ${({ theme }) => theme.orange};
  }

  .Toastify__toast--error {
    color: ${({ theme }) => theme.red};
  }

  .Toastify__close-button {
    color: #979797;
  }

  .Toastify__progress-bar {
    border-radius: 4px;
    bottom: 16px !important;
    left: 18px !important;
    width: calc(100% - 40px) !important;
  }

  .Toastify__toast--success .Toastify__progress-bar {
    background-color: ${({ theme }) => theme.darkGreen};
  }

  /* .Toastify__toast--warning .Toastify__progress-bar {
    background-color: ${({ theme }) => theme.orange};
  } */

  .Toastify__toast--error .Toastify__progress-bar {
    background-color: ${({ theme }) => theme.red};
  }

  .Toastify__progress-bar_background {
    background-color: #d8d8d8;
    border-radius: 4px;
    bottom: 16px;
    height: 5px;
    left: 18px;
    position: absolute;
    width: calc(100% - 40px);
  }
`;

export const phoneInputStyles = css`
  .react-tel-input {
    font-family: 'paralucent-medium', sans-serif;
    font-size: 16px;
  }

  .phone-number {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 30px;

    &__input {
      font-size: 16px;
      flex: 1;
      width: auto;
      border-radius: 9px;
      outline: none;
      color: #1e1e1e;
      border: 2px solid ${({ theme }) => theme.grey};
      padding: 11px;
      line-height: inherit;
      height: auto;
      transition: 0.2s border-color, 0.2s color;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-background-clip: text;
      }

      &:focus {
        border-color: ${({ theme }) => theme.green};
      }
    }

    &__button,
    .flag-dropdown.phone-number__button {
      position: static;
      margin-right: 7px;
      background-color: ${({ theme }) => theme.white};
      border: 2px solid ${({ theme }) => theme.grey};
      transition: 0.2s border-color, 0.2s color;
      border-radius: 9px;

      .selected-flag {
        border-radius: 9px;
      }
    }

    &__button,
    .flag-dropdown.open.phone-number__button {
      border: 2px solid ${({ theme }) => theme.green};
    }

    &__dropdown {
      border-radius: 10px;
      width: 100%;
      border: 2px solid ${({ theme }) => theme.grey};
    }

    &--error {
      .phone-number {
        &__input {
          color: ${({ theme }) => theme.red};
          border-color: ${({ theme }) => theme.red} !important;
        }

        &__button {
          border-color: ${({ theme }) => theme.red} !important;
        }
      }
    }
  }
`;

export const swiperStyles = css`
  .swiper-container {
    max-height: 100vh;
    max-width: 100%;
    min-height: 0;
    min-width: 0;
    width: 100%;
  }
`;
